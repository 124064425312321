import React, { Component } from 'react';
import cloudinary from 'cloudinary-jquery-file-upload/cloudinary-jquery-file-upload.js'
import jquery from 'jquery/dist/jquery.js';
import blueImpWidget from 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import blueImpTransport from 'blueimp-file-upload/js/jquery.iframe-transport.js'
import blueImpUpload from 'blueimp-file-upload/js/jquery.fileupload.js'
import { Controlled as CodeMirror } from 'react-codemirror2';
import Pusher from 'pusher-js';
import pushid from 'pushid';
import axios from 'axios';
import atclogo from '../../images/loader.png';
import {Modal} from 'react-bootstrap'
import '../../App.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

    class pgEditor extends Component{
      constructor(props) {
        super(props);
        this.state = {
          id:'',
          html:'',
          addModalShow:'',
          cloudImage:'',
          taskByteValue:'',
          projectName:''

        };
  this.Imageupload = this.Imageupload.bind(this)
  this.savePortfolioContent = this.savePortfolioContent.bind(this)
  this.retrievePortfolioContent = this.retrievePortfolioContent.bind(this)
  this.closeModal = this.closeModal.bind(this)
      }

    async componentDidUpdate() {
     
      }

     async componentDidMount() {
    
         console.log(this.state.resourceRef);
        const {match:{params}} = this.props   
        console.log(params.projectName);
  
         this.Imageupload()
         
          this.setState({
            projectName: params.projectName
            
          }, () => this.retrievePortfolioContent() )
      }

    
      async Imageupload(e) {

        await  jquery.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
        // Initiate upload
      
      var myWidget = window.cloudinary.createUploadWidget({
      cloudName: 'www-code4teen-tech', 
      uploadPreset: 'csizcotf'}, (error, result) => { 
      if (!error && result && result.event === "success") { 
        console.log('Done! Here is the image info: ', result.info.secure_url); 
          this.setState({
               cloudImage:result.info.secure_url
             })
      }
      }
      )
      
      
      document.getElementById("upload_widget").addEventListener("click", function(){
      myWidget.open();
      }, false);
      
      
      }


      async closeModal(){
        this.setState({ 
          addModalShow: false
        })
      }


async retrievePortfolioContent(){



        const storeProjectCode = await fetch('https://atcbrain.herokuapp.com/learning-engine/portfolio-api/v1/readProjectCode', { 
         
         method:"POST",
         body: JSON.stringify({
          projectName: this.state.projectName
         }),
         headers:{
          "content-type" : "application/json"
         },
         mode:"cors",
         credentials: "include"

 })
 //.then(response => response.json())
 .then(response => {
         console.log(response)
 })


}

async savePortfolioContent(){

     const fileContent = this.state.html 
     const projectName = this.state.projectName

     console.log(fileContent)
     console.log(projectName)


      const storeProjectCode = await fetch('https://atcbrain.herokuapp.com/learning-engine/portfolio-api/v1/storeProjectCode', {          
 
         method:"POST",
         body: JSON.stringify({
          fileContent: this.state.html ,
          projectName: this.state.projectName
         }),
         headers:{
          "content-type" : "application/json"
         },
         mode:"cors",
         credentials: "include"

 })
 .then(response => response.json())
 .then(response => {
         console.log(response)

         this.setState({
          addModalShow : true
         })
 })

}
 
responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
    setTimeout(() => {
        new ResizeObserver(listen).observe(body);
    }, 1000);



    //******listen to changes****** 
    function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }
         
    }


    
//running the editor code 
      runCode = () => {
        const {html} = this.state;

        const iframe = this.refs.iframe;
        const document = iframe.contentDocument;
        const documentContents = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Document</title>
            <style>
           
            </style>
          </head>
          <body>
            ${html}
          </body>
          </html>
        `;

        document.open();
        document.write(documentContents);
        document.close();
      };

//rendering the component as JSX 

      render() {

           

        const { html } = this.state;
        const codeMirrorOptions = {
          theme: 'lucario',
          mode: 'htmlmixed', 
          tabMode: 'indent',
          lineNumbers: true,
          scrollbarStyle: null,
          lineWrapping: true,
          smartIndent: true,
          electricChars: true,
          autofocus: true,
          lineSeparator:"    "
          
        };
       
         
//jsx creation 

        return (
          <div className="container standard-padding-tb-2 top-right-curve bottom-right-curve">
          <div className="row standard-padding-tb-3"> 
             <div className="col-12 col-md-4 text-center"> 
             <figure> 
              <figcaption className="">
               <p className="body-font yellow-background">  Project Name : {this.state.projectName} </p> 
              </figcaption> 
             </figure>
             </div> 
             <div className="col-12 col-md-8 text-right"> 
              <div className="row standard-padding-tb-2">
          <div className="col-12">  
              <button id="upload_widget" className="btn btn-outline-success cloudinary-button" > Upload a picture and receive a URl below </button>
                <button id="" className="btn btn-outline-success" onClick={this.savePortfolioContent}> Save </button>
        <p className="tiny-font margin-off white-text"> {this.state.cloudImage} </p>
            </div>

          </div>  

              
               

             </div> 
     
          </div> 
         
          <div className="App row">
         
            <section className="playground col-12 col-md-6">
              <div className="code-editor html-code">
                <div className="editor-header">HTML</div>
                <CodeMirror className="codeMirror-thin pgEditor"
                  value={html}
                  options={{
                    mode: 'htmlmixed',
                    ...codeMirrorOptions,
                  }}
                  onBeforeChange={(editor, data, html) => {

                    this.setState({ html });
                  }}

                  onChange={this.runCode}
                  
                />
              </div>
            </section>
            <section className="result col-12 col-md-6 white-background padding-off">
              <iframe title="result" className="iframe" ref="iframe" />
            </section>
          </div>

          <Modal show={this.state.addModalShow} animation={false}>
           <Modal.Header>
          <Modal.Title> Yipee!  </Modal.Title>
        </Modal.Header>
        <Modal.Body> Your code has been saved </Modal.Body>
        <Modal.Footer>
          
          <button className="btn btn-danger white-text" onClick={this.closeModal}> close </button>
        
        </Modal.Footer>
      </Modal>
          
          </div>
        );
      }
    }

    export default pgEditor