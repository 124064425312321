import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import start from './start';
import cloudinary from 'cloudinary-jquery-file-upload/cloudinary-jquery-file-upload.js'
import jquery from 'jquery/dist/jquery.js';
import {Modal} from 'react-bootstrap'



class assessment extends Component { 
   constructor(props) { 
    super(props);

     this.state ={ 
       projectName:"",
       cloudImage:"",
       addModalShow:false 
     }; 


     this.Imageupload = this.Imageupload.bind(this)
     this.createNewPortfolioProject = this.createNewPortfolioProject.bind(this)
   }

   async componentDidMount(){ 
        this.Imageupload()
   }


updateProjectName = (event) => { 
      this.setState({
            projectName: event.target.value
       })

       let projectTitle = document.getElementById('projectTitle')

       projectTitle.innerHTML = jquery('#projectName').val()
  }


    
  async Imageupload(e) {

    await  jquery.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
    // Initiate upload
  
  var myWidget = window.cloudinary.createUploadWidget({
  cloudName: 'www-code4teen-tech', 
  uploadPreset: 'csizcotf'}, (error, result) => { 
  if (!error && result && result.event === "success") { 
    console.log('Done! Here is the image info: ', result.info.secure_url); 
      this.setState({
           cloudImage:result.info.secure_url
         })
  
  }
  }
  )
  
  
  document.getElementById("upload_widget").addEventListener("click", function(){
  myWidget.open();
  }, false);
  
  
  }


async createNewPortfolioProject(){ 


  if(this.state.projectName === "" || this.state.cloudImage === ""){
    alert("add a project name and Image")
  }else {

    let pname = this.state.projectName
    console.log(pname);
    console.log(pname)

    const portfolioObject={ 
      projectName: pname,
      projectImage: this.state.cloudImage

   }

let data = JSON.stringify(portfolioObject)

let submitCandidateData = fetch('https://atcbrain.herokuapp.com/learning-engine/portfolio-api/v1/createnewportfoliorecord', { 
         method: "POST",
          headers:{
            "content-type" : 'application/JSON'
          },
         body: data,
         credentials:"include",
         mode: "cors"
})
.then(response=> response.json())
.then(response => {
    if(response){
    console.log(response)


    this.setState({

      addModalShow: true,
    })
  window.responsiveVoice.speak(`Your project is now created. Use the button below to start building`) 

    }
  console.log(response)
}

  )


  }
     

}



   render() { 

     return(

          <div> 
<div className="row standard-padding-tb">
    
    <div className="col-8">

        <h2 className="accent-font white-text">  Starting your new project </h2>
        <h6 className="body-font white-text">  To create a new project, fill in the following details and press save to continue </h6>


     <div className="col-12 text-center" id="createProject"> 

                      <div className="form-group text-center standard-padding-tb">
                        <label className="body-font white-text">Project Name</label>
                          <input type="text" value={this.state.projectName} onChange={this.updateProjectName} className="form-control input-field-width mx-auto standard-round-box-curve" id="projectName" aria-describedby="emailHelp" placeholder="Name of project"/>
                        </div>


                      <div className="col-9 form-group text-center standard-padding-tb mx-auto">
                        <label className="body-font white-text">Project Image</label>
                         

                         <button id="upload_widget" className="btn btn-outline-success form-control cloudinary-button" > Upload a picture for your project </button>
  
             
      
                        </div>
          

      
       <button className="btn btn-success standard-round-box-curve green-background white-text header-font tabs-btn-hover " onClick={this.createNewPortfolioProject}>    Create Project </button>



     </div> 


    </div>  

    <div className="col-4"> 

        <h3 className="accent-font white-text"> Project Preview </h3>
        <h6 className="body-font white-text"> Confirm your project details before saving </h6> 

      <div className="row">

        <div className="col-12"> 
        

       <h6 className="white-text"> 
         PROJECT TITLE
       </h6>
          <h3 className="header-font white-text" id="projectTitle"> 
             
          </h3>
          <h6 className="white-text"> 
         PROJECT IMAGE
       </h6>

          <img width="90%" src={this.state.cloudImage} />

        </div>
      </div>

    </div> 

    </div>

    <Modal show={this.state.addModalShow} animation={false}>
           <Modal.Header>
          <Modal.Title> Yipee!  </Modal.Title>
        </Modal.Header>
        <Modal.Body> your project is now created. Use the button below to start building </Modal.Body>
        <Modal.Footer>
          <Link to={`/playground/start/project/${this.state.projectName}`}>
          <button className="btn btn-danger white-text"> Start building </button>
          </Link>
        </Modal.Footer>
      </Modal>



          </div>

     	)

   }
   

}

export default assessment