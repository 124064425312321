import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import jquery from '../node_modules/jquery/dist/jquery.js'
import {useCookies} from "react-cookie";
import Nav from './components/nav'
import Login from './components/login'
import * as msTeams from '@microsoft/teams-js';
window.$ = window.jQuery=jquery;

    class App extends Component{


 constructor(props) {
    super(props);

    this.state = {
   
    };
    msTeams.initialize();
    msTeams.getContext(context => { this.updateTheme(context.theme) });

  msTeams.settings.registerOnSaveHandler(saveEvent => {

    const host = "https://" + window.location.host
    msTeams.settings.setSettings({
       contentUrl: "https://books.thebeeskneesschool.com",
       websiteUrl: "https://books.thebeeskneesschool.com",
      suggestedDisplayName: "Andrew Teaches Coding",
      entityId: "Andrew Teaches Coding"
    });

    saveEvent.notifySuccess();
  });
  msTeams.settings.setValidityState(true);

  
    
  }

     render() { 

        return (
       
          <div className="App">
          <Nav />
           <Login />
          
          </div>
     
        );
      }
    }

    export default App;
