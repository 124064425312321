import React,{Component} from 'react';
import { MDBInput } from 'mdbreact';
import $ from 'jquery/dist/jquery.js';
import atclogo from '../../images/loader.png';
import whoAmILogo from '../../images/quiz-icon.png';






class editorQuizB extends Component{ 
   constructor(props){
   	super(props)

   	this.state = {
       resourceRef:this.props.resourceRef, 
       html:'',
       codeSnippet:"loading",
       question:"",
       optionA:"",
       optionB:"", 
       optionC:"", 
       visualHolder:"",
       visual:"",
       visualB:"",
       answer:"",
       taskByteValue:"",
       studentResponse:""

   	}

 this.fetchQuizResource = this.fetchQuizResource.bind(this);
 this.quizResponseUpdate = this.quizResponseUpdate.bind(this);
 this.textReader = this.textReader.bind(this);
 

   }

  async componentDidUpdate(prevState){  
  
 
  }


  async componentDidMount(){
    
  	this.props.taskStatus(false);
  	await this.fetchQuizResource(); 
  	console.log(this.state.userAnswer);
    this.props.quizAnswer(this.state.answer);
    this.props.byteState(this.state.taskByteValue);
    //this.responsiveClass();

  }


  updateStudentResponse = (event) => { 
    this.setState({
          studentResponse: event.target.value
     })
}



async quizResponseUpdate(event){
    console.log(this.state.studentResponse)
     
    await  this.props.quizResponse(this.state.studentResponse)
           this.props.validateQuiz()
       if(this.state.answer === this.state.studentResponse){ 
        console.log(this.state.visualB)
        this.setState({
          visual: this.state.visualB
        })
       }else{
          this.setState({
            visual: this.state.visualHolder
          })
       }



     
}

async fetchQuizResource(){

     const requestResource = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.resourceRef}/quizTask`, {
      method:"POST", 
      headers:{
      	"Content-Type" : "application-json"
      },
      mode:"cors",
      credentials:"include"

     })
     .then(response => response.json())
     .then(response => {console.log(response[0])

          this.setState({ 
                question: response[0].question,
                optionA: response[0].optionA,
                optionB: response[0].optionB,
                optionC: response[0].optionC, 
                visualHolder: response[0].visual,
                visual: response[0].visual,
                visualB: response[0].visualB,
                answer: response[0].answer,
                taskByteValue: response[0].byteValue,
                codeSnippet: response[0].codeSnippet
          })



     })
}

async textReader(){

  console.log('spoke')

  $.when(window.responsiveVoice.speak(`Analyse This`))
  .then(window.responsiveVoice.speak(`${this.state.question}`))
  .then(window.responsiveVoice.speak(`is it ${this.state.optionA}`))
  .then(window.responsiveVoice.speak(`or ${this.state.optionB}`))
  .then(window.responsiveVoice.speak(`or ${this.state.optionC}`))
             
}






runCode = () => {
    const {html} = this.state;

    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    const documentContents = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <title>Document</title>
        <style>
       
        </style>
      </head>
      <body>
        ${html}
      </body>
      </html>
    `;

    document.open();
    document.write(documentContents);
    document.close();
  };










render() {



	return(
  <div className="bottom-right-curve top-right-curve black-background" id="editor-properties" > 
   <div className="row standard-padding-tb-3 black-background top-right-curve" id="editor-instructions"> 
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
             <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={whoAmILogo} width="60"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background tiny-font"> Interface Analysis</p> 
               <p className="accent-font tiny-font white-text mobile-text"> Analyse the interface Below </p>
              </figcaption> 
             </figure>
             </div>      

             <div className="col-md-10 standard-margin-top-quiz">
              <div className="row">

               <div className="col-12 text-center"> 
                 
               <img width="90%" src={this.state.visual} />
         

               </div>
               <div className="col-12">
               <div className="row">
                 <div className="col-md-9 mx-auto">
                 <div className="form-group text-center">
                 <p className="header-font white-text mobile-text"> {this.state.question} </p>
                      <label className="body-font white-text"> Type your answer </label>
                        <input type="text" value={this.state.studentResponse} onChange={this.updateStudentResponse} className="form-control input-field-width standard-round-box-curve mx-auto" id="studentResponse" placeholder="Answer"/>
                      </div>
                 </div>
             

    
     </div>
     <div className="col-md-12 text-center"> 
       <button id="" onClick={this.quizResponseUpdate}  className="btn btn-outline-success"> Submit </button>
     </div> 
   </div>
              </div>
        
            </div>

   </div> 


 </div>

		)
}



}

export default editorQuizB