import React, {Component} from 'react';
import{Link} from 'react-router-dom';

class progress extends Component { 
   constructor(props) { 
    super(props);

     this.state ={ 
        topics :[]
     }; 

     this.retrieveProjectList = this.retrieveProjectList.bind(this)
   }


   async componentDidMount(){ 
     
     await this.retrieveProjectList()
     console.log(this.state.topics)

   }


    async retrieveProjectList(){

    	const topicList = await fetch('https://atcbrain.herokuapp.com/learning-engine/porfolio-api/v1/findAllProjects',{ 
          method: "GET",
          headers:{ 
            "Content-Type" : "application/json"
          },
          mode:"cors",
          credentials:"include"
    	})
    	.then(response => response.json())
    	.then(response => {console.log(response) 
            
               this.setState({
               	topics:response
               })
    	})

    }


   render() { 

      if(!this.state.topics.length){ 
          return null;
      }

   	  let projectList = this.state.topics.map(topic =>
         
          
          <div className="blue-background-lightcol-12 standard-margin-top col-md-4 IWH" id="navbarStyle"> 

          <div className="badge blue-background-2 white-text"> {topic.projectName} </div>
            <img src={topic.projectImage} width="100%"/>
       <Link to={`/portfolio/${topic.projectName}/preview/${topic._id}`}> 
          <button className="btn btn-success standard-margin-top standard-round-box-curve green-background white-text header-font tabs-btn-hover"> Open </button>
       </Link>

      <Link to={`/portfolio/${topic.projectName}/edit/${topic._id}`}> 
          <button className="btn btn-success standard-margin-top standard-round-box-curve green-background white-text header-font tabs-btn-hover"> Edit </button>
       </Link>

          </div>




         
            )

     return(

         <div className="container browserPadding">
          <div className="row standard-margin-top">

          <div className="col-12"> 
           <h3 className="accent-font"><span className="accent-font">{this.props.candidateFirstName}'s Portfolio</span> </h3> 
            <h6 className="body-font"> Click on any project to view your progress </h6> 
          </div>

          </div>

       <div className="row blue-background-light">
          {projectList}

           </div>
         </div>

     	)
     }
   }

export default progress