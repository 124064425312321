import React,{Component} from 'react';
import $ from 'jquery/dist/jquery.js';
import { MDBInput } from 'mdbreact';
import atclogo from '../../images/loader.png';
import {Badge} from 'react-bootstrap';
import whoAmILogo from '../../images/quiz-icon.png';





class whoAmI2 extends Component{ 
   constructor(props){
   	super(props)

   	this.state = {
       taskRef:this.props.taskRef, 
       taskByteValue:"",
       check:''

   	}



 this.liftQuizResponse = this.liftQuizResponse.bind(this);
 this.resetSubmissionStatusToFalse = this.resetSubmissionStatusToFalse.bind(this);
   this.textReader = this.textReader.bind(this);

   }

  async componentDidUpdate(prevProps){  
  
     console.log('updated')
  }


  async componentDidMount(){
 
   //this.props.byteState(this.state.taskByteValue);
   this.props.taskStatus(false)
   this.resetSubmissionStatusToFalse()
  }



async resetSubmissionStatusToFalse(){ 

   let resetQuizStatus = await fetch('https://atcbrain.herokuapp.com/learning-engine/response-api/v1/assessments/unsubmit', { 
    method: "POST",
    headers:{
      'content-type':'application/JSON'
    },
    mode:'cors',
    credentials:'include'
   })
  
   .then(response => response.json())
   .then(response => console.log (response))

}


async liftQuizResponse(event){
 
 let quizFormInput = document.getElementById('quiz')

 let response = $(quizFormInput).val()

 console.log(event.target.value)

 this.props.htmlChange(event.target.value)


}


async textReader(){

  console.log('spoke')

  $.when(window.responsiveVoice.speak(`Riddle me this`))
  .then(window.responsiveVoice.speak(`${this.props.question}`))
  .then(window.responsiveVoice.speak(`is it ${this.props.optionA}`))
  .then(window.responsiveVoice.speak(`or ${this.props.optionB}`))
  .then(window.responsiveVoice.speak(`or ${this.props.optionC}`))
             
}


responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
    setTimeout(() => {
        new ResizeObserver(listen).observe(body);
    }, 1000);



    //******listen to changes****** 
    function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }
         
    }




render() {

const divStyle = {
      backgroundImage: 'url("../../images/hero-large.png")',
      WebkitTransition: 'all', // note the capital 'W' here
      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
};

const optionsArray= [this.props.optionA, this.props.optionB, this.props.optionC]

const optionsList = optionsArray.map(

	options => <div className="col-md-4 text-center white-text header-font"> <MDBInput onChange={this.liftQuizResponse} id="quiz" label={options} filled type="radio" name="opt" value={options} />  </div>

	)

	return(
  <div className="bottom-right-curve top-right-curve" id="editor-properties" > 
   <div className="row standard-padding-tb-3 black-background top-right-curve full-width ml-off" id="editor-instructions"> 
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
               <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={whoAmILogo} width="60"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background tiny-font"> Who am i </p> 
               <p className="accent-font tiny-font white-text"> Complete the tasks by following the instructions </p>
              </figcaption> 
             </figure>
             </div>      

             <div className="col-md-10 standard-margin-top-quiz">
              <div className="row">


                <div className="col-12 text-right">
               <Badge className="badge badge-danger" variant="danger">Graded</Badge>

             </div>

               <div className="col-12"> 
                     <h3 className="header-font white-text"> Riddle </h3> 
             <p className="header-font white-text"> {this.props.question} </p>
         

               </div>
               <div className="col-12">
               <div className="row">
     {optionsList}
     </div>
   </div>
              </div>
        
            </div>

    <div className="col-12 text-center top-clear">
          <button id="run" className="btn btn-outline-success" onClick={this.props.quizResponseUpdate}> Save </button>
     </div>

   </div> 

   <div className="row bottom-right-curve full-width ml-off"> 


 <div className="col-12 padding-off bottom-right-curve CodeMirror2"> 
   <img className="bottom-right-curve" src={this.props.visual} width="100%" height="100%" /> 
  </div>





   </div>
 </div>

		)
}



}

export default whoAmI2