import React, { Component } from 'react';
import cloudinary from 'cloudinary-jquery-file-upload/cloudinary-jquery-file-upload.js'
import jquery from 'jquery/dist/jquery.js';
import blueImpWidget from 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import blueImpTransport from 'blueimp-file-upload/js/jquery.iframe-transport.js'
import blueImpUpload from 'blueimp-file-upload/js/jquery.fileupload.js'
import { Controlled as CodeMirror } from 'react-codemirror2';
import Pusher from 'pusher-js';
import pushid from 'pushid';
import axios from 'axios';
import atclogo from '../../images/loader.png';
import {Modal} from 'react-bootstrap'
import '../../App.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

    class browserSimulation extends Component{
      constructor(props) {
        super(props);
        this.state = {
          id:'',
          html:'',
          addModalShow:'',
          cloudImage:'',
          taskByteValue:'',
          projectName:''

        };

  this.retrievePortfolioContent = this.retrievePortfolioContent.bind(this);


      }

    async componentDidUpdate() {
       this.runCode()
      }

     async componentDidMount() {
    
         console.log(this.state.resourceRef);
        const {match:{params}} = this.props   
        console.log(params.projectName);
  
         
         
       await   this.setState({
            projectName: params.projectName,
            portfolioId: params.id
            
          }, () => this.retrievePortfolioContent() )

         

        
      }


async retrievePortfolioContent(){

const storeProjectCode = await fetch(`https://atcbrain.herokuapp.com/learning-engine/portfolio-api/v1/readProjectCode/`, { 
         
         method:"POST",
         body: JSON.stringify({
          portfolioId: this.state.portfolioId
         }),
         headers:{
          "content-type" : "application/json"
         },
         mode:"cors",
         credentials: "include"

 })
 .then(response => response.json())
 .then(response => {
     console.log(response)
        this.setState({
            html: response
        })
 })


}

//change portfolio background color to white 

 
responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
    setTimeout(() => {
        new ResizeObserver(listen).observe(body);
    }, 1000);



    //******listen to changes****** 
    function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }
         
    }


    
//running the editor code 
      runCode = () => {
        const {html,projectName} = this.state;
        let projectNameTrim = projectName.replace(/\s/g, '')

        const iframe = this.refs.iframe;
        const document = iframe.contentDocument;
        const documentContents = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-eOJMYsd53ii+scO/bJGFsiCZc+5NDVN2yr8+0RDqr0Ql0h+rP48ckxlpbzKgwra6" crossorigin="anonymous">
            <script src="https://use.fontawesome.com/3406efcc5f.js"></script>
            <script   src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"   integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="   crossorigin="anonymous"></script>
            <style>
            /* ATC BROWSER */
            body{
                box-sizing: border-box;
                padding:0;
                margin: 0;
                overflow-x: hidden;
            }
            
            .atc-browser{
                background-color: black;
                width:100%;
                margin: 0px;
            }
            .atc-browser1 img{
                width: 80px;
                margin: 10px
            }
            .atc-browser .addressField{
                background-color: #fff;
                height: 60px;
                border-bottom-left-radius:30px ;
            }
            
            .atc-browser .addressField p{
                color: black;
                font-size: 2rem;
                padding: 10px 15px 0px;
            }
            .addressField{
                display: flex;
                justify-content: space-between !important;
                margin-bottom: 1%
            }

            .closeField{
                background-color: red;
                height: 60px;
                border-bottom-left-radius:30px ;
            }
             .fa-times{
                color: white !important;
                font-size: 2rem;
                padding: 20px 10px 0px;
                cursor: pointer;
            }
            </style>
          </head>
          <body>
          <div class="row atc-browser text-white" style="">
          <div class="col-3 atc-browser1">
              <img src="https://res.cloudinary.com/code4teen-tech/image/upload/v1624848224/candidates/Andrew.png" width="10px" alt=""/>
              <span>ATC BROWSER</span>
          </div>
          <div class="col-8">
              <div class="addressField">
                  <p>https://www.${projectNameTrim}.com</p>           
              </div>
          </div>
          <div class="col-1 text-right closeField id="closeBtn"">
       
          </div>
          
      </div>

            ${html}

   
    
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.1/dist/umd/popper.min.js" integrity="sha384-SR1sx49pcuLnqZUnnPwx6FCym0wLsk5JZuNx2bPPENzswTNFaQU1RDvt3wT4gWFG" crossorigin="anonymous"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.min.js" integrity="sha384-j0CNLUeiqtyaRmlzUHCPZ+Gy5fQu0dQ6eZ/xAww941Ai1SxSY+0EQqNXNE6DZiVc" crossorigin="anonymous"></script>
        
        <script> 
        $(document).ready(function(){
        $('#closeBtn').click(function(){ 
            window.location.href("http://www.w3schools.com")
        })
       })
        </script>
          </body>
          </html>
        `;

        document.open();
        document.write(documentContents);
        document.close();
      };

//rendering the component as JSX 

      render() {

           

        const { html } = this.state;
        const codeMirrorOptions = {
          theme: 'lucario',
          mode: 'htmlmixed', 
          tabMode: 'indent',
          lineNumbers: true,
          scrollbarStyle: null,
          lineWrapping: true,
          smartIndent: true,
          electricChars: true,
          autofocus: true,
          lineSeparator:"    "
          
        };
       
         
//jsx creation 

        return (
          <div className="container browserPadding standard-padding-tb-2 black-background top-right-curve bottom-right-curve">
         
          <div className="App row ">
        
            <section className="result col-12 col-md-12 white-background padding-off">
              <iframe title="result" className="iframe" id="labBoxStyleClass" ref="iframe" />
            </section>
          </div>

          <Modal show={this.state.addModalShow} animation={false}>
           <Modal.Header>
          <Modal.Title> Yipee!  </Modal.Title>
        </Modal.Header>
        <Modal.Body> Your code has been saved </Modal.Body>
        <Modal.Footer>
          
          <button className="btn btn-danger white-text" onClick={this.closeModal}> close </button>
        
        </Modal.Footer>
      </Modal>
          
          </div>
        );
      }
    }

    export default browserSimulation