import React, { Component } from 'react';
import cloudinary from 'cloudinary-jquery-file-upload/cloudinary-jquery-file-upload.js'
import jquery from 'jquery/dist/jquery.js';
import blueImpWidget from 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import blueImpTransport from 'blueimp-file-upload/js/jquery.iframe-transport.js'
import blueImpUpload from 'blueimp-file-upload/js/jquery.fileupload.js'
import { Controlled as CodeMirror } from 'react-codemirror2';
import Pusher from 'pusher-js';
import pushid from 'pushid';
import axios from 'axios';
import atclogo from '../../images/loader.png';
import '../../App.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

    class Mirror extends Component{
      constructor(props) {
        super(props);
        this.state = {
          id:'',
          html:'',
          resourceRef:this.props.resourceRef,
          title:'',
          codeSnippet:'',
          taskDescription:'',
          taskA:'',
          taskB:'',
          taskC:'',
          taskD:'',
          taskE:'',
          taskF:'',
          taskG:'',
          positiveAlert:'',
          negativeAlert:'',
          cloudImage:'',
          taskByteValue:'',
          taskTag:''
        };

  this.fetchEditorResource = this.fetchEditorResource.bind(this)
  this.runCode = this.runCode.bind(this)
  this.textReader = this.textReader.bind(this)
  this.retrievePreviousEditorTaskResponse = this.retrievePreviousEditorTaskResponse.bind(this)
  this.Imageupload = this.Imageupload.bind(this)
  this.checkForTaskEditorEntry = this.checkForTaskEditorEntry.bind(this)
  this.unfreezeRun = this.unfreezeRun.bind(this)
  
      }

    async componentDidUpdate() {
     
      }

     async componentDidMount() {
    
         console.log(this.state.resourceRef);
        //const {match:{params}} = this.props   
        //console.log(params.topidRef);
           await this.fetchEditorResource()

          this.props.taskStatus(false)
          this.props.byteState(this.state.taskByteValue)
          

          this.setState({
            positiveAlert:false,
            negativeAlert:false
          })

          this.Imageupload()
      }



      async fetchEditorResource() { 
        const requestedResource = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.resourceRef}/editorTask`,{
          method:"POST",
          headers:{
            'Content-Type': "application-JSON"
          }, 
          mode:"cors",
          credentials:"include", 
        })
           .then(response => response.json())
           .then(response => { 
                 console.log(response)
    
                 this.setState({ 
                      taskDescription: response.taskDescription,
                      codeSnippet: response.codeSnippet,
                      taskA: response.taskA, 
                      taskB: response.taskB, 
                      taskC: response.taskC, 
                      taskD: response.taskD,
                      taskE: response.taskE,
                      taskF: response.taskF, 
                      taskG: response.taskG,
                      taskByteValue: response.byteValue,
                      taskRecall: response.recall,
                      taskTag : response.tag,
                      
                 }, async ()=> { 
                    
                     await this.checkForTaskEditorEntry()  
                      
                 })
    
                
           })
     }
    
    
     async unfreezeRun(){ 
          console.log(this.state.taskTag)
          const sendCurrentTaskTag = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.taskTag}/unfreezeRunButton`,{
            method:"POST",
            headers:{
              'Content-Type': "application-JSON"
            }, 
            mode:"cors", 
            credentials:"include"
           })
      
            .then(response => response.json())
            .then(response => {
              console.log(response)
              if(response){
                let runButton = document.getElementById('run')
                runButton.removeAttribute('disabled')
              }
    
              if(!response){
                let runButton = document.getElementById('run')
                runButton.setAttribute('disabled','')
              }
    
            })
    
     }
    
    
     async checkForTaskEditorEntry(){ 
         const checkForEntry = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.resourceRef}/editorEntryCheck`,{
          method:"POST",
          headers:{
            'Content-Type': "application-JSON"
          }, 
          mode:"cors", 
          credentials:"include"
         })
    
          .then(response => response.json())
          .then(response => {
            console.log(response)
                if(response.entry){
                  //check if entry is correct for task
                 console.log(response.response)
    
                 this.setState({
                  html: response.response
                 },async function(){
                  //update html value in parent compoent 
                  await this.props.htmlChange(this.state.html)
                  //validate response to set pacing arrow 
                      this.props.eventValidatingResponse()
    
                      
    
                 })
                 
                 
    
                 // this.props.taskStatus(true)
                  this.setState({ 
                    codeSnippet : response.response,
                    taskStatus: this.props.checkTaskCompletionUpdate
    
              }, ()=> { 
                if(this.state.taskStatus){
                    console.log(this.state.taskStatus)
                let runButton = document.getElementById('run')
                runButton.setAttribute('disabled','')
     
                }
              })
                }
    
               if(!response.entry){
                console.log("I ran")
                console.log(this.state.taskRecall)
                if(this.state.taskRecall){
                  console.log("I ran 2")
                  this.retrievePreviousEditorTaskResponse()
                }
              
               } 
          })
    
     }
 
 async retrievePreviousEditorTaskResponse(){ 
       
   const requestedEditorResponse = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.taskTag}/editorResponse`,{
       method:"POST",
       headers:{
         'Content-Type': "application-JSON"
       }, 
       mode:"cors", 
       credentials:"include"
     })
        .then(response => response.json())
        .then(response => { 
            console.log(response.response)
            if(response.response){
             this.setState({ 
               codeSnippet : response.response
         })
 
            }else{
             
            this.setState({ 
              codeSnippet : ""
        })
  
            }


 
            
      
                 
        })
 }

async textReader(){

  console.log('spoke')

  jquery.when(window.responsiveVoice.speak(`Ok`))
  .then(window.responsiveVoice.speak(`${this.state.taskDescription}`))
  .then(window.responsiveVoice.speak(`${this.state.taskA}`))
  .then(window.responsiveVoice.speak(`${this.state.taskB}`))
             
}

    
async Imageupload(e) {

  await  jquery.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
  // Initiate upload

var myWidget = window.cloudinary.createUploadWidget({
cloudName: 'www-code4teen-tech', 
uploadPreset: 'csizcotf'}, (error, result) => { 
if (!error && result && result.event === "success") { 
  console.log('Done! Here is the image info: ', result.info.secure_url); 
    this.setState({
         cloudImage:result.info.secure_url
       })
    this.props.taskStatus(true)
}
}
)


document.getElementById("upload_widget").addEventListener("click", function(){
myWidget.open();
}, false);


}


 
 async liftHtmlState(){
  let thtml =  this.state.html
  await thtml.trim()
  console.log(thtml)
  this.props.htmlChange(this.state.html)
 }

 

 



    
//running the editor code 
      runCode = () => {
        const {html} = this.state;

        const iframe = this.refs.iframe;
        const document = iframe.contentDocument;
        const documentContents = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Document</title>
            <style>
           
            </style>
          </head>
          <body>
            ${html}
          </body>
          </html>
        `;

        document.open();
        document.write(documentContents);
        document.close();
       // this.unfreezeRun()
      };

//rendering the component as JSX 

      render() {

           

        const { html } = this.state;
        const codeMirrorOptions = {
          theme: 'lucario',

          mode: 'htmlmixed', 
          tabMode: 'indent',
          value: this.state.codeSnippet,
          lineNumbers: true,
          scrollbarStyle: null,
          lineWrapping: true,
          smartIndent: true,
          electricChars: true,
          autofocus: true,
          lineSeparator:"    "
          
        };
       
       const  taskArray1=[ this.state.taskA, this.state.taskB, this.state.taskC, this.state.taskD]
       const  taskArray2=[ this.state.taskE, this.state.taskF, this.state.taskG]
       const  taskList1 = taskArray1.map(task => <p className="blue-text body-font"> {task} </p> )
       const  taskList2 = taskArray2.map(task => <p className="blue-text body-font"> {task} </p> )

         
//jsx creation 

        return (
          <div className="container standard-padding-tb-2 black-background top-right-curve bottom-right-curve" id="editor-properties">
          <div className="row standard-padding-tb-3"> 
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
             <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={atclogo} width="100"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background"> Editor task </p> 
               <p className="accent-font tiny-font white-text"> Complete the tasks by following the instructions </p>
              </figcaption> 
             </figure>
             </div> 
             <div className="col-12 col-md-10 text-center"> 
              <div className="row standard-padding-tb-2 text-center"> 
              <div className="col-12"> 
               <h6 className="body-font blue-text"> {this.state.taskDescription} </h6> 
              </div>
              <div className="col-12 task-steps-box">
                {taskList1}
              </div> 
              <div className="col-12">
            <button id="run" className="btn btn-outline-success" onClick={this.props.validatingResponse}> Run </button>     
            <button id="upload_widget" className="btn btn-outline-success cloudinary-button" > Upload a picture and recieve a URl below </button>
            <p className="tiny-font margin-off white-text"> {this.state.cloudImage} </p>
            </div>

          </div>  

              
               

             </div> 
     
          </div> 
         
          <div className="App row">
         
            <section className="playground col-12 col-md-6">
              <div className="code-editor html-code">
                <div className="editor-header">HTML</div>
                <CodeMirror className="codeMirror-thin"
                  value={html}
                  options={{
                    mode: 'htmlmixed',
                    ...codeMirrorOptions,
                  }}
                  onBeforeChange={(editor, data, html) => {

                    this.setState({ html }, () => this.liftHtmlState());
                  }}

                  onChange={this.runCode}
                  
                />
              </div>
            </section>
            <section className="result col-12 col-md-6 white-background padding-off">
              <iframe title="result" className="iframe" ref="iframe" />
            </section>
          </div>
          
          </div>
        );
      }
    }

    export default Mirror